<template>
  <div>
    <!-- <div class="vx-row mb-12">
      <vs-button
        v-on:click="processPod()"
        icon="done"
        color="success"
        style="margin-right: 5px"
        >Process</vs-button
      >
      <vs-button v-on:click="processGenerateInv()" color="primary"
        >Generate Inv</vs-button
      >
    </div> -->
    <div class="vx-row mb-12 mt-2">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <vs-button class="mb-5" color="success" @click="handleShowPrompt"
          >Generate invoice</vs-button
        >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="renderData"
        >
          <template slot="thead">
            <vs-th style="width: 5%">
              <vs-checkbox v-model="checkedAll"> Check All </vs-checkbox>
            </vs-th>
          </template>

          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment">
              <vs-td
                ><vs-checkbox
                  v-on:click="check(tr.id, indextr)"
                  :checked="checked.includes(tr.id)"
                ></vs-checkbox>
              </vs-td>
              <vs-td>
                <vx-tooltip text="Details" class="mr-4">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-on:click.stop="handleShow(tr)"
                  /> </vx-tooltip
              ></vs-td>
              <vs-td> {{ tr.shipment_number }}</vs-td>
              <vs-td>
                Customer Code : {{ tr.customer_code }}
                <br />
                Customer Name : {{ tr.customer_name }}
                <br />
                Ship To : ({{tr.ship_to_code}} - {{tr.ship_to_name}}) - {{ tr.customer_address }}
                <br />
              </vs-td>
              <vs-td>
                Code : {{ tr.so_code }}
                <br />
                Posting Date :
                {{ tr.so_posting_date ? tr.so_posting_date.split("T")[0] : "" }}
                <br />
                Invoice Code : {{ tr.so_invoice_code }}
              </vs-td>
              <vs-td>
                Code : {{ tr.do_code }}
                <br />
                Posting Date :
                {{ tr.do_posting_date ? tr.do_posting_date.split("T")[0] : "" }}
              </vs-td>
              <vs-td>
                {{ capitalize(tr.notes) }}
              </vs-td>
              <vs-td>
                {{ generatePODStatus(tr.status_delivery) }}
              </vs-td>
              <vs-td>
                <template v-if="tr.transaction != null">
                  Subtotal :
                  {{ priceFormatNoDecimal(tr.transaction.header.TotalAmount) }}
                  <br />
                  Tax :
                  {{
                    priceFormatNoDecimal(tr.transaction.header.TotalTaxAmount)
                  }}
                  <br />
                  Discount :
                  {{
                    priceFormatNoDecimal(tr.transaction.header.TotalDiscount)
                  }}
                  <br />
                  Total :
                  {{
                    priceFormatNoDecimal(
                      tr.transaction.header.TotalAmountAfterTaxAndDiscount
                    )
                  }}
                  <br />
                </template>
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div class="flex flex-col">
            <div
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </div>
            <!-- <form-apply :payment="selectedData" @closeDetail="closeDetail" /> -->
            <pod_detail :podData="selectedData" />
          </div>
        </div>
      </transition>
    </div>

    <vs-prompt
      @cancel="handleClosePrompt"
      @accept="processGenerateInv"
      @close="handleClosePrompt"
      :active.sync="showPrompt"
      title="Confirmation"
    >
      <div class="flex flex-col gap-3">
        <div class="flex flex-col gap-1">
          <span>Posting Date</span>
          <date-picker
            class="w-full"
            :inline="false"
            name="Posting Date"
            v-validate="'required'"
            v-model="postingDate"
            :disabledDates="{
              from: new Date(),
              to:
                dataChecked && dataChecked[0] && dataChecked[0].do_posting_date
                  ? getDoDate(dataChecked[0].do_posting_date)
                  : new Date(),
            }"
          />
        </div>
        <span class="font-bold">Are you sure want to generate invoice(s)?</span>
      </div>
    </vs-prompt>
    <vs-popup
      title="Failed To Generate"
      :active.sync="showFailedToGenerate"
      button-close-hidden
    >
      <div class="flex flex-col gap-5">
        <div
          v-if="failedToGenerate.length > 0"
          class="flex flex-col p-4 gap-4"
          style="height: 40dvh; max-height: 50dvh; overflow-y: auto"
        >
          <vx-card
            v-for="(tr, index) in failedToGenerate"
            :key="index"
            style="background-color: #ea5455"
          >
            <div class="flex flex-col gap-3 font-bold text-white">
              <p>PI Number : {{ tr.pi_number }}</p>
              <p>Customer : {{ tr.customer_code }} - {{ tr.customer_name }}</p>
            </div>
          </vx-card>
        </div>
        <div class="w-full flex justify-end">
          <vs-button color="danger" @click="handleCloseFailedToGenerate"
            >Close</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
//   import formApply from "./apply-form.vue";
import pod_detail from "./pod_detail.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import VxCard from "../../../../components/vx-card/VxCard.vue";
export default {
  props: {
    isReload: Boolean,
  },
  components: {
    pod_detail,
    "date-picker": Datepicker,
    VxCard,
  },
  data() {
    return {
      typePayment: ["", "Trade", "Deposit", "Open Deposit", "Credit Note"],
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "shipment_number",
      },
      header: [
        {
          text: "Action",
        },
        {
          text: "Shipment Doc",
          value: "customer_code",
          // width: '5%'
        },
        {
          text: "Customer Data",
          value: "reference_code",
        },
        {
          text: "SO Data",
          sortable: false,
        },
        {
          text: "DO/DN Data",
          value: "created_at",
        },
        {
          text: "Source",
          value: "created_at",
        },
        {
          text: "POD Status",
          value: "created_at",
        },
        {
          text: "Value",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      checkedAll: false,
      checked: [],
      dataChecked: [],
      showPrompt: false,
      postingDate: null,
      failedToGenerate: [],
      showFailedToGenerate: false,
      closeByButton: false,
      shouldReload: false,
    };
  },
  computed: {},
  watch: {
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
    },
    checkedAll() {
      if (this.checkedAll) {
        this.checked = this.responseData.payment.map((v) => v.id);
        this.dataChecked = this.responseData.payment;
      } else {
        this.checked = [];
        this.dataChecked = [];
      }
    },
    showFailedToGenerate() {
      if (this.showFailedToGenerate) {
        return;
      }

      if (this.closeByButton) {
        this.closeByButton = false;
        this.failedToGenerate = [];
      } else {
        this.showFailedToGenerate = true;
      }
    },
  },
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
    this.postingDate = new Date();
  },
  methods: {
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
    },
    closeDetail(params = false) {
      // window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    applyPayment(tr) {
      this.detail = true;
      this.selectedData = tr;
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    renderData(params) {
      if (this.shouldReload) {
        this.params = {
          ...params,
        }
        this.reloadData(this.params);
      }
    },
    addChecked(val) {
      if (this.checked.includes(val.id)) {
        this.checked.splice(this.checked.indexOf(val.id), 1);
        this.dataChecked.splice(this.checked.indexOf(val.id), 1);
      } else {
        this.checked.push(val.id);
        this.dataChecked.push(val);
      }
      console.log(this.dataChecked, "check");
      console.log(this.checked.length, "leng");
    },
    addAllChecked() {
      console.log(this.responseData.payment);
      if (this.checkedAll == false) {
        for (var i = 0; i < this.responseData.payment.length; i++) {
          if (this.checked.includes(this.responseData.payment[i].id)) {
            console.log(this.responseData.payment, "first if");
          } else {
            this.checked.push(this.responseData.payment[i].id);
            this.dataChecked.push(this.responseData.payment[i]);
            console.log(this.responseData.payment[i], "second if");
          }
        }
      } else {
        for (var j = 0; j < this.responseData.payment.length; j++) {
          console.log(this.responseData.payment, "third if");
          this.checked.splice(
            this.checked.indexOf(this.responseData.payment[j].id),
            1
          );
          this.dataChecked = this.dataChecked.filter((v) => {
            return v.id != this.responseData.payment[j].id;
          });
        }
      }
    },

    check(val, index) {
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked.splice(
          this.dataChecked.findIndex((pod) => pod.id === val),
          1
        );
      } else {
        this.checked.push(val);
        this.dataChecked.push(this.responseData.payment[index]);
      }
      console.log(this.dataChecked, "check");
      console.log(this.checked, "leng");
    },
    datatable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/proof-of-delivery", {
            params: {
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              type: 1,
              shipment_number: null,
              warehouse_id: 0,
              start_delivery_date: null,
              end_delivery_date: null,
              territory_ids: params.territory_ids,
              customer_codes: params.customer_codes.includes("All")
                ? undefined
                : params.customer_codes,
              is_ar_invoice: true,
              status_not_in: params.status_not_in,
              // status: params.status ? params.status : 0,
              // type: params.type ? params.type : 0,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    reloadData(params) {
      // console.log("territory ids", territory_ids);
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      const customer_codes =
        this.$store.getters["customerFilter/getSelectedCustomersCodes"];
      this.params = {
        ...params,
        territory_ids,
        customer_codes,
        status_not_in: [7],
      };
      console.log(params);
      this.$vs.loading();

      const dataTable = this.datatable(this.params);
      dataTable.then((r) => {
        this.shouldReload = true;
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          // this.data = r.data.payment;
          this.responseData = r.data;
          console.log("r.data", r.data);
          // this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    processGenerateInv() {
      const params = {
        pod_ids: this.checked,
        posting_date: moment(this.postingDate).format("YYYY-MM-DD"),
      };

      this.$vs.loading();
      this.$http
        .post("/api/v1/invoice/pod", params)
        .then((result) => {
          this.$vs.loading.close();
          //   // if(r.stat)
          if (result.code == 500) {
            this.$vs.notify({
              title: "Failed",
              text: result.message,
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
              color: "danger",
            });
            this.$vs.loading.close();
            // this.reloadData(this.params);
          } else if (result.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: result.message,
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check-circle",
              color: "success",
            });
            this.$vs.loading.close();
            this.checked = [];
            this.dataChecked = [];
            this.failedToGenerate = result.data;
            if (this.failedToGenerate.length > 0) {
              this.showFailedToGenerate = true;
            }
            // this.reloadData(this.params);
          }
          // this.reloadData(this.params);
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Failed",
            text: e.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
        })
        .finally(() => {
          this.reloadData(this.params);
        });
      // this.reloadData(this.params);
    },
    generatePODStatus(statusNumber) {
      switch (statusNumber) {
        case 0:
          return "Open";
        case 1:
          return "Complete";
        case 2:
          return "Partial Complete";
        case 3:
          return "Partial Redeliver";
        case 4:
          return "Failed Complete";
        case 5:
          return "Failed Redeliver";
      }
    },
    capitalize(str) {
      if (str.includes("ePod") || str.includes("Pod")) {
        return str;
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    handleShowPrompt() {
      if (this.checked.length < 1) {
        this.$vs.notify({
          title: "Failed",
          text: "Please at least choose one POD",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle",
          color: "danger",
        });
        return false;
      }

      if (!this.validatePostingDate()) {
        this.$vs.notify({
          title: "Failed",
          text: "Please choose POD with the same posting date",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle",
          color: "danger",
        });
        return false;
      }
      this.postingDate = moment
        .utc(this.dataChecked[0].do_posting_date)
        .toDate();
      this.showPrompt = true;
    },
    handleClosePrompt() {
      this.showPrompt = false;
      this.postingDate = new Date();
    },
    validatePostingDate() {
      const postingDate = this.dataChecked[0].do_posting_date.split("T")[0];
      return this.dataChecked.every((v) => {
        return v.do_posting_date.split("T")[0] === postingDate;
      });
    },
    getDoDate(date) {
      return moment.utc(date).toDate();
    },
    handleCloseFailedToGenerate() {
      this.showFailedToGenerate = false;
      this.closeByButton = true;
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 46%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
