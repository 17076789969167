<template>
  <div class="flex flex-row w-full mt-6 gap-4" style="min-height: 300px">
    <div class="flex flex-col w-2/3 gap-6">
      <!-- <multiselect
        v-model="invoiceData"
        :options="option"
        :multiple="true"
        placeholder="Type to search"
        :limit="10"
        :max-height="160"
        ref="invoiceInput"
        @search-change="onSearch"
      >
      </multiselect> -->
      <div class="flex gap-6 justify-center items-center">
        <div class="flex flex-col w-full gap-1 h-full">
          <span>From</span>
          <multiselect
            v-model="invoiceStartData"
            :options="invoiceStartDataOptions"
            :multiple="false"
            placeholder="Type to search"
            ref="invoiceInput"
            track-by="id"
            @search-change="onSearchStart"
            :custom-label="invoiceQuery"
            class="w-full h-full"
            :max-height="130"
          >
          </multiselect>
        </div>

        <p class="text-2xl mt-4">-</p>
        <div class="flex flex-col w-full gap-1">
          <span>To</span>
          <multiselect
            v-model="invoiceToData"
            :options="invoiceToDataOptions"
            :multiple="false"
            placeholder="Type to search"
            ref="invoiceInput"
            track-by="id"
            @search-change="onSearchTo"
            :custom-label="invoiceQuery"
            class="w-full h-full"
            :max-height="130"
          >
          </multiselect>
        </div>
      </div>

      <div class="flex gap-3 justify-end w-full">
        <div class="flex flex-col w-full gap-1 h-full">
          <span>Invoice Code</span>
          <multiselect
            v-model="invoiceData"
            :options="invoiceOptions"
            :multiple="true"
            placeholder="Type to search"
            :limit="50"
            :max-height="160"
            track-by="id"
            ref="invoiceInput"
            @search-change="onSearch"
            :custom-label="invoiceQuery"
          >
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span> {{ props.option.code }}</span>
                <i
                  class="multiselect__tag-icon"
                  @click.prevent
                  @mousedown.prevent.stop="props.remove(props.option, $event)"
                />
              </div>
            </template>
          </multiselect>
        </div>
      </div>

      <div class="flex gap-3 justify-end w-full">
        <vs-button
          icon-pack="feather"
          icon="icon-download"
          type="border"
          @click="printPDFMultiple(false)"
          >Download</vs-button
        >

        <vs-button
          icon-pack="feather"
          icon="icon-printer"
          type="border"
          color="success"
          @click="printOpenTab(false)"
          >Print</vs-button
        >

        <vs-button
          icon-pack="feather"
          icon="icon-file-text"
          type="border"
          color="success"
          @click="printOpenTab(true)"
          >Print Billing List</vs-button
        >
      </div>

      <vs-popup
        fullscreen
        title="Print"
        :active.sync="showPdf"
        :button-close-hidden="false"
      >
        <div class="flex flex-col gap-6 h-full">
          <iframe :src="pdfUrl" class="w-full" style="min-height: 80vh" />
          <div class="flex gap-3 w-full justify-end">
            <vs-button color="danger" @click="handleClosePopUp"
              >Cancel</vs-button
            >
            <vs-Button @click="handlePrintMultiplePDFPreview">Print</vs-Button>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- <vx-tooltip text="Remove All" style="max-width: fit-content">
      <vs-button
        icon-pack="feather"
        icon="icon-x"
        type="border"
        color="danger"
        @click="removeInvoice"
      />
    </vx-tooltip> -->
  </div>
</template>
<script>
import moment from "moment";
import jsPDF from "jspdf";
import { bulkUpdateInvoice } from "../../../../services/api/invoice";
import invoiceHelper, {
  printInvoiceList,
} from "../../../../helpers/invoice-helper";
import debounce from "debounce";
export default {
  data() {
    return {
      invoiceData: [],
      invoiceStartData: null,
      invoiceStartDataOptions: [],
      invoiceToData: null,
      invoiceToDataOptions: [],
      invoiceOptions: [],
      showPdf: false,
      pdfUrl: "",
      pdfProxy: null,
      debounceFunction: null,
      isFromSearch: false,
      isToSearch: false,
    };
  },
  props: {
    isReload: Boolean,
    postingDate: {
      type: Object,
      default: () => ({}),
    },
    paymentTerm: {
      type: Object,
      default: () => ({}),
    },
  },
  async mounted() {
    const territory_ids =
      this.$store.getters["operatingUnit/getSelectedTerritoryId"];

    await this.getInvoiceStart("", territory_ids);
    this.invoiceToDataOptions = this.invoiceStartDataOptions;
  },
  watch: {
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.invoiceOptions = [];
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      this.getInvoiceStart("", territory_ids);
      this.invoiceToDataOptions = this.invoiceStartDataOptions;
    },
    invoiceStartData() {
      if (
        !this.invoiceStartData ||
        Object.keys(this.invoiceStartData).length === 0 ||
        !this.invoiceToData ||
        Object.keys(this.invoiceToData).length === 0
      ) {
        return;
      }

      if (!this.validateInvoice()) {
        return;
      }

      this.getInvoiceCodeByRange(
        this.invoiceStartData.code,
        this.invoiceToData.code
      );
    },
    invoiceToData() {
      if (
        !this.invoiceStartData ||
        Object.keys(this.invoiceStartData).length === 0 ||
        !this.invoiceToData ||
        Object.keys(this.invoiceToData).length === 0
      ) {
        return;
      }

      if (!this.validateInvoice()) {
        return;
      }

      this.getInvoiceCodeByRange(
        this.invoiceStartData.code,
        this.invoiceToData.code
      );
    },
  },
  computed: {
    selectedInvoiceCodes() {
      return this.invoiceData.map((invoice) => invoice.code);
    },
    selectedInvoiceIDs() {
      if (this.invoiceData.length === 0) {
        return null;
      }
      return this.invoiceData.map((invoice) => invoice.id);
    },
  },
  methods: {
    handleClosePopUp() {
      this.pdfProxy = false;
      this.showPdf = false;
      this.selectedInvoice = {};
      this.pdfUrl = "";
      this.$store.commit("arInvoice/resetInvoice");
      this.clearSessionStorage();
    },
    handleShowPopUp() {
      this.showPdf = true;
    },
    clearSessionStorage() {
      if (sessionStorage.getItem("param_type")) {
        sessionStorage.removeItem("param_type");
      }

      if (sessionStorage.getItem("start_code")) {
        sessionStorage.removeItem("start_code");
      }

      if (sessionStorage.getItem("end_code")) {
        sessionStorage.removeItem("end_code");
      }

      if (sessionStorage.getItem("is_billing_list")) {
        sessionStorage.removeItem("is_billing_list");
      }

      if (sessionStorage.getItem("invoice_codes")) {
        sessionStorage.removeItem("invoice_codes");
      }

      if (sessionStorage.getItem("pdfBlob")) {
        sessionStorage.removeItem("pdfBlob");
      }

      if (sessionStorage.getItem("invoice_id")) {
        sessionStorage.removeItem("pdfBlob");
      }
    },

    async handlePrintMultiplePDFPreview() {
      try {
        let fileName;
        if (this.$store.state.arInvoice.selectedInvoices.length === 1) {
          fileName = `invoice-${this.$store.state.arInvoice.selectedInvoices[0].Code}.pdf`;
        } else {
          fileName = `invoice-bulk_${moment().format("DD-MM-YYYY")}.pdf`;
        }

        await this.updatePrintBulk();
        this.pdfProxy.save(fileName);
        this.handleClosePopUp();
        this.$store.commit("arInvoice/resetInvoice");
      } catch (e) {
        console.log(e);
      }
    },

    async updatePrintBulk() {
      try {
        this.$vs.loading();
        const invoiceIDs =
          this.$store.getters["arInvoice/getselectedInvoicesIds"];
        const resp = await bulkUpdateInvoice(invoiceIDs);
        if (resp.code > 299) {
          throw new Error("Failed to update print count");
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        throw e;
      }
    },

    async printPDFMultiple(isPrint = false) {
      try {
        if (!this.validateInvoice()) {
          return;
        }

        const padding = 1;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        // let cursorY = 0;

        pdf.setFontSize(13);
        pdf.setFont("courier", "bold");
        // const invoiceIDs = this.$store.getters["arInvoice/getselectedInvoicesIds"];

        const url = await invoiceHelper.printPDF(
          pdf,
          null,
          isPrint,
          pageWidth,
          pageHeight,
          padding,
          this.invoiceStartData.code,
          this.invoiceToData.code,
          this.selectedInvoiceIDs
        );

        if (isPrint) {
          await this.updatePrintBulk();
          sessionStorage.setItem("pdfBlob", url);
          this.$refs.printButton.$el.click();
        } else {
          // const url = pdf.output("dataurlstring");
          // this.pdfUrl = url + "#toolbar=0";
          this.pdfUrl = url;
          this.pdfProxy = pdf;
          this.handleShowPopUp();
          this.$vs.loading.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    onSearch(search) {
      if (search.includes(";")) {
        search = search.trim();
        search = search.replace(/^\n+|\n+$/g, "");
        this.invoiceData = search.split(";");
        this.$refs.invoiceInput.deactivate();
      } else {
        const isAvailable = this.invoiceOptions.some((option) =>
          option.code.includes(search)
        );

        if (isAvailable) {
          return;
        }

        if (this.debounceFunction) {
          this.debounceFunction.clear();
        }

        this.debounceFunction = debounce(() => {
          this.getInvoiceCodeByRange(
            this.invoiceStartData.code,
            this.invoiceToData.code,
            search
          );
        }, 700);

        this.debounceFunction();
      }
    },
    removeInvoice() {
      this.invoiceData = [];
    },
    getInvoice(params) {
      return this.$http.get("/api/v1/invoice/for-options", { params });
    },
    async getInvoiceStart(search, territory_ids) {
      try {
        const customer_codes =
          this.$store.getters["customerFilter/getSelectedCustomersCodes"];
        this.$vs.loading();
        const resp = await this.getInvoice({
          search,
          territory_ids,
          source_not_in: ["begbal"],
          payment_term:
            Object.keys(this.paymentTerm).length > 0
              ? this.paymentTerm.name
              : "All",
          start_posting_date:
            Object.keys(this.postingDate).length > 0 &&
            this.postingDate.startDate
              ? this.formatDate(this.postingDate.startDate)
              : undefined,
          end_posting_date:
            Object.keys(this.postingDate).length > 0 && this.postingDate.endDate
              ? this.formatDate(this.postingDate.endDate)
              : undefined,
          customer_codes: customer_codes.includes("All")
            ? undefined
            : customer_codes,
        });
        if (resp.code > 299) {
          this.$vs.loading.close();
          throw new Error("Failed to get invoice data");
        }
        this.invoiceStartDataOptions = resp.data;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.error(e);
      }
    },
    async getInvoiceTo(search, territory_ids) {
      try {
        this.$vs.loading();
        const resp = await this.getInvoice({
          search,
          territory_ids,
          source_not_in: ["begbal"],
        });
        if (resp.code > 299) {
          this.$vs.loading.close();
          throw new Error("Failed to get invoice data");
        }
        this.invoiceToDataOptions = resp.data;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.error(e);
      }
    },
    onSearchStart(search) {
      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }

      this.debounceFunction = debounce(() => {
        this.isFromSearch = true;
        const territory_ids =
          this.$store.getters["operatingUnit/getSelectedTerritoryId"];
        this.getInvoiceStart(search, territory_ids);
      }, 700);

      this.debounceFunction();
    },
    onSearchTo(search) {
      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }

      this.debounceFunction = debounce(() => {
        this.isToSearch = true;
        const territory_ids =
          this.$store.getters["operatingUnit/getSelectedTerritoryId"];
        this.getInvoiceTo(search, territory_ids);
      }, 700);

      this.debounceFunction();
    },
    onclose() {
      if (this.isFromSearch) {
        this.isFromSearch = false;
        this.getInvoiceStart(
          "",
          this.$store.getters["operatingUnit/getSelectedTerritoryId"]
        );
      } else if (this.isToSearch) {
        this.isToSearch = false;
        this.getInvoiceTo(
          "",
          this.$store.getters["operatingUnit/getSelectedTerritoryId"]
        );
      }
    },
    invoiceQuery({ code }) {
      if (code) {
        return `${code}`;
      } else {
        return "";
      }
    },
    validateInvoice() {
      if (
        !this.invoiceStartData ||
        Object.keys(this.invoiceStartData).length === 0
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Please select invoice from",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      if (!this.invoiceToData || Object.keys(this.invoiceToData).length === 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select invoice to",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      let startCode = this.invoiceStartData.code.split("-")[1];
      let toCode = this.invoiceToData.code.split("-")[1];

      const startCodeTerritory = this.invoiceStartData.code.split("-")[0];
      const toCodeTerritory = this.invoiceToData.code.split("-")[0];

      const startCodeMonth = startCode.substring(2, 4);
      const startCodeYear = toCode.substring(4, 6);
      const startCodeRemaining = startCode.substring(6);

      const endCodeMonth = toCode.substring(2, 4);
      const endCodeYear = toCode.substring(4, 6);
      const endCodeRemaining = toCode.substring(6);

      startCode = parseInt(startCodeYear + startCodeMonth + startCodeRemaining);
      toCode = parseInt(endCodeYear + endCodeMonth + endCodeRemaining);

      console.log(startCode, toCode);

      if (startCode >= toCode) {
        this.$vs.notify({
          title: "Error",
          text: "Invoice from must be older",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      if (startCodeTerritory !== toCodeTerritory) {
        this.$vs.notify({
          title: "Error",
          text: "Invoice from and invoice to are not in the same territory",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }
      return true;
    },

    async printBillingList() {
      try {
        if (!this.validateInvoice()) {
          return;
        }

        const padding = 1;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        const url = await printInvoiceList(
          pdf,
          2.6,
          pageWidth,
          pageHeight,
          padding,
          this.invoiceStartData.code,
          this.invoiceToData.code
        );

        sessionStorage.setItem("pdfBlob", url);
        this.$refs.printButton.$el.click();
        this.$store.commit("arInvoice/resetInvoice");
      } catch (e) {
        console.log(e);
      }
    },
    printOpenTab(isBillingList = false) {
      if (!this.validateInvoice()) {
        return;
      }

      sessionStorage.setItem("start_code", this.invoiceStartData.code);
      sessionStorage.setItem("end_code", this.invoiceToData.code);
      sessionStorage.setItem("param_type", "code,id");

      if (isBillingList) {
        sessionStorage.setItem("is_billing_list", "true");
      } else {
        sessionStorage.setItem("is_billing_list", "false");
      }

      if (this.invoiceData && this.invoiceData.length > 0) {
        sessionStorage.setItem(
          "invoice_id",
          JSON.stringify(this.selectedInvoiceIDs)
        );
      }

      // if (this.invoiceData && this.invoiceData.length > 0) {
      //   sessionStorage.setItem(
      //     "invoice_codes",
      //     JSON.stringify(this.selectedInvoiceCodes)
      //   );
      // }

      window.open("/billing/print-invoice", "_blank");
      this.$store.commit("arInvoice/resetInvoice");
      this.checkAll = false;
    },
    getInvoiceCodeByRange(starCode, endCode, search = "") {
      this.$vs.loading();
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      const customer_codes =
        this.$store.getters["customerFilter/getSelectedCustomersCodes"];
      this.$http
        .get("/api/v1/invoice/for-options", {
          params: {
            start_code: starCode,
            end_code: endCode,
            territory_ids,
            search,
            payment_term:
              Object.keys(this.paymentTerm).length > 0
                ? this.paymentTerm.name
                : "All",
            start_posting_date:
              Object.keys(this.postingDate).length > 0 &&
              this.postingDate.startDate
                ? this.formatDate(this.postingDate.startDate)
                : undefined,
            end_posting_date:
              Object.keys(this.postingDate).length > 0 &&
              this.postingDate.endDate
                ? this.formatDate(this.postingDate.endDate)
                : undefined,
            customer_codes: customer_codes.includes("All")
              ? undefined
              : customer_codes,
          },
        })
        .then((res) => {
          this.$vs.loading.close();
          if (res.code > 299) {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get invoice data",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return;
          }
          this.invoiceOptions = res.data;
        })
        .catch((e) => {
          this.$vs.loading.close();
          console.error(e);
        });
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
};
</script>
