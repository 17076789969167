<template>
  <div class="flex flex-col gap-4">
    <div class="flex gap-3">
      <vs-button @click="printPDFMultiple(false)">Download</vs-button>
      <vs-button @click="printOpenTab(null, false)">Print</vs-button>
      <vs-button @click="printOpenTab(null, true)"
        >Print Billing List</vs-button
      >
    </div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <!-- 
       ADDONs
         
        -->

        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="renderData"
        >
          <template slot="thead">
            <vs-th
              ><vs-checkbox @click="addAllInvoice" :checked="checkAll"
                >Check All</vs-checkbox
              ></vs-th
            >
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.invoices"
              v-bind:class="[selectedData.ID == tr.ID ? 'colored' : '', '']"
              style="
                 {
                  border: 1px solid;
                }
              "
            >
              <vs-button
                v-if="selectedData.ID == tr.ID"
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >

              <!-- <vs-td :data="data[indextr].ID">{{data[indextr].ID}}</vs-td> -->
              <vs-td>
                <vs-checkbox @click="addCheck(tr)" :checked="isChecked(tr)" />
              </vs-td>
              <vs-td>
                <template>
                  <vx-tooltip text="Download Invoice">
                    <vs-button
                      color="success"
                      type="line"
                      icon-pack="feather"
                      @click="printPDFSingle(tr, false)"
                      icon="icon-download"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Print Invoice">
                    <vs-button
                      color="success"
                      type="line"
                      icon-pack="feather"
                      @click="printOpenTab(tr, false)"
                      icon="icon-printer"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Send Invoice">
                    <vs-button
                      color="orange"
                      type="line"
                      icon-pack="feather"
                      @click="sendEmail(tr.ID)"
                      icon="icon-mail"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Payment Invoice">
                    <vs-button
                      color="blue"
                      type="line"
                      icon-pack="feather"
                      @click="paymentInvoice(tr)"
                      icon="icon-file-text"
                    />
                  </vx-tooltip>
                </template>
              </vs-td>
              <vs-td>
                Code : {{ tr.Code }}
                <br />
                Faktur : {{ tr.FakturNumber }}
                <br />
                PI Number : {{ tr.DoCode ? tr.DoCode : "-" }}
                <br />
                Date : {{ dateFormat(tr.Date) }}
                <br />
                Due Date : {{ dateFormat(tr.DueDate) }}
                <br />
                Posting Date : {{ dateFormat(tr.PostingDate) }}
                <br />
                Sales Order Code : {{ tr.SalesOrderCode }}
                <br />
                Created Date : {{ dateFormat(tr.CreatedAt) }}
                <br />
                Invoice Reference Code : {{ tr.InvoiceReferenceCode }}
                <br />
                Note : {{ tr.Notes }}
                <br />
              </vs-td>
              <vs-td>
                Code : {{ tr.ClientCode }}
                <br />
                Name : {{ tr.ClientName }}
                <br />
                Ship To : ({{tr.ShipToCode}} - {{tr.ShipToName}}) - {{tr.ShipToAddress}}
              </vs-td>
              <vs-td>
                Gross Total :
                {{
                  priceFormatNoDecimal(
                    tr.Sources.toLowerCase() === "generate"
                      ? tr.Subtotal + tr.Discount - tr.Rounding
                      : tr.Subtotal
                  )
                }}
                <br />
                Discount : {{ priceFormatNoDecimal(tr.Discount - tr.Rounding) }}
                <br />
                Subtotal :
                {{
                  priceFormatNoDecimal(
                    tr.Sources.toLowerCase() === "generate"
                      ? tr.Subtotal
                      : tr.Subtotal - tr.Discount
                  )
                }}
                <br />
                Subtotal Charge : {{ priceFormatNoDecimal(tr.SubtotalCharge) }}
                <br />
                Gross Up : {{ priceFormatNoDecimal(tr.GrossUp) }}
                <br />
                Other Cost : {{ priceFormatNoDecimal(tr.OtherCost) }}
                <br />
                DPP :
                {{
                  priceFormatNoDecimal(
                    tr.Sources.toLowerCase() === "generate"
                      ? tr.Subtotal + tr.GrossUp
                      : tr.Subtotal - tr.Discount + tr.GrossUp
                  )
                }}
                <br />
                Tax : {{ priceFormatNoDecimal(tr.TaxAmount) }}
                <br />
                Total : {{ priceFormatNoDecimal(tr.Total) }}
                <br />
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <vs-popup
        fullscreen
        title="Print"
        :active.sync="showPdf"
        :button-close-hidden="false"
      >
        <div class="flex flex-col gap-6 h-full">
          <iframe :src="pdfUrl" class="w-full" style="min-height: 80vh" />
          <div class="flex gap-3 w-full justify-end">
            <vs-button color="danger" @click="handleClosePopUp"
              >Cancel</vs-button
            >
            <vs-Button @click="handlePrintMultiplePDFPreview">Print</vs-Button>
          </div>
        </div>
      </vs-popup>
      <!-- detail-->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>

            <form-payment
              :invoice="selectedData"
              @closeDetail="closeDetail"
            ></form-payment>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import form from "./form.vue";
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import invoiceHelper, {
  printInvoiceList,
} from "../../../../helpers/invoice-helper";
import { bulkUpdateInvoice } from "../../../../services/api/invoice";
export default {
  components: {
    "form-payment": form,
  },
  props: {
    selected: Object,
    option: Object,
    isReload: Boolean,
    postingDate: {
      type: Object,
      default: () => ({}),
    },
    paymentTerm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Action",
          sortable: false,
          width: "5%",
        },
        {
          text: "Invoice",
          value: "code",
          // width: '5%'
        },
        {
          text: "Client",
          sortable: false,
        },
        {
          text: "Amount",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      showPdf: false,
      pdfUrl: "",
      pdfProxy: null,
      invoiceReadData: {},
      checkAll: false,
      shouldReload: false,
    };
  },
  computed: {
    territory: {
      get: function () {
        return (
          "(" +
          this.selected.territory.code +
          ") " +
          this.selected.territory.name
        );
      },
    },
  },
  watch: {
    territory: function () {
      // this.kitting.territory = this.selected.territory
      this.reloadData(this.params);
    },
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
    },
  },
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].ID
  },
  methods: {
    printInvoice(invoice) {
      let name = "invoice-consignment-sales-print";
      if (this.$route.fullPath == "/billing") {
        name = "invoice-print";
      }

      let routeData = this.$router.resolve({
        name: name,
        params: { id: invoice.ID },
      });
      console.log(routeData.href);
      window.open(routeData.href, "_blank");
      // window.print()
    },
    handleClosePopUp() {
      this.pdfProxy = false;
      this.showPdf = false;
      this.selectedInvoice = {};
      this.pdfUrl = "";
      this.checkAll = false;
    },
    handleShowPopUp() {
      this.showPdf = true;
    },
    renderData(params) {
      if (this.shouldReload) {
        this.params = {
          ...params,
        }
        this.reloadData(this.params);
      }
    },
    handlePrintPDFPreview() {
      const invoice = this.selectedInvoice;
      const fileName = `invoice-${invoice.Code}.pdf`;
      this.pdfProxy.save(fileName);
      this.handleClosePopUp();
      this.updatePrintCount(invoice.ID);
    },
    calculatePaymentTerm(date, due_date) {
      const dueDate = moment(due_date);
      const invoiceDate = moment(date);
      const diff = dueDate.diff(invoiceDate, "days");
      return diff;
    },
    getInvoiceData(invoice_id) {
      return this.$http.get("api/v1/invoice/read", {
        params: {
          invoice_id,
        },
      });
      // .then((resp) => {
      //   if (resp.code == 200) {
      //     // const asyncFunction = async () => {
      //     this.invoiceValue(resp.data);
      //     console.log("promise1Result");
      //     // window.print();
      //     this.$nextTick(() => {
      //       window.print();
      //       // window.close()
      //     });
      //     console.log("promise2Result");
      //     this.$vs.loading.close();

      //     // }
      //     // console.log(asyncFunction)
      //     // this.isEditorShow = true;
      //   } else {
      //     // //console.log(resp.code)
      //     this.$vs.dialog({
      //       type: "alert",
      //       color: "danger",
      //       title: `Alert`,
      //       text: "Whoops",
      //     });
      //     this.$vs.loading.close();
      //   }
      // });
    },
    generateNumberSaying(value) {
      return angkaTerbilang(value).toUpperCase();
    },
    generateInvoiceInformation(
      pdf,
      invoice,
      salesOrder,
      salesMan,
      customer,
      cursorY,
      pageWidth
    ) {
      const header = [
        { header: "", dataKey: "title" },
        { header: "", dataKey: "delimiter" },
        { header: "", dataKey: "value" },
      ];
      const body = [
        // { title: "Print", delimiter: ":", value: invoice.PrintCount + 1 },
        { title: "Billing No.", delimiter: ":", value: invoice.Code },
        {
          title: "Billing Date",
          delimiter: ":",
          value: moment(invoice.Date).format("DD.MM.YYYY"),
        },
        {
          title: "Order No.",
          delimiter: ":",
          value: salesOrder && salesOrder.Code ? salesOrder.Code : "-",
        },
        {
          title: "Order Date",
          delimiter: ":",
          value:
            salesOrder && salesOrder.Date
              ? moment(salesOrder.Date).format("DD.MM.YYYY")
              : "-",
        },
        { title: "Customer No.", delimiter: ":", value: invoice.ClientCode },
        {
          title: "Sales Office",
          delimiter: ":",
          value: this.getTerritoryCode(invoice.TerritoryID),
        },
        {
          title: "Condition Delivery",
          delimiter: ":",
          value:
            salesOrder && salesOrder.DeliveryTypeName
              ? salesOrder.DeliveryTypeName
              : "-",
        },
        {
          title: "Term of Payment",
          delimiter: ":",
          value: `Due within ${this.calculatePaymentTerm(
            invoice.Date,
            invoice.DueDate
          )} days`,
        },
        {
          title: "Due Date",
          delimiter: ":",
          value: moment(invoice.DueDate).format("DD.MM.YYYY"),
        },
        {
          title: "Tax Status/Currency",
          delimiter: ":",
          value: `${customer.is_pkp === "0" ? "Non PKP" : "PKP"} / IDR`,
        },
        {
          title: "Sales Code/Salesman",
          delimiter: ":",
          value: `${
            salesMan && salesMan.CodeExternal ? salesMan.CodeExternal : "-"
          } / ${salesMan && salesMan.Name ? salesMan.Name : "-"}`,
        },
      ];
      autoTable(pdf, {
        startY: cursorY,
        columns: header,
        body: body,
        showHead: "never",
        styles: {
          lineWidth: 0,
          fontSize: 11,
          font: "courier",
          valign: "top",
        },
        columnStyles: {
          title: { cellWidth: 5, cellPadding: 0 },
          delimiter: { cellWidth: 0.5, cellPadding: 0 },
          value: { cellWidth: 5, cellPadding: 0 },
        },
        theme: "plain",
        margin: {
          left: this.setPos(pageWidth, pageWidth / 2 + 1, 1),
          right: 1,
        },
        didParseCell(data) {
          const columnIndex = data.column.index;
          if (columnIndex === 2) {
            data.cell.styles.halign = "left";
          }
        },
      });

      return pdf.lastAutoTable.finalY;
    },
    generateInvoiceLine(pdf, resp, cursorY, pageWidth, pageHeight, padding) {
      const invoice = resp.data.invoice;
      const invoiceLines = resp.data.invoiceLine[0].Line;
      let prevPage = 1;
      let isDrawLine = false;
      let isDraw = false;
      const header = [
        { header: "Product", dataKey: "product" },
        { header: "Quantity", dataKey: "qty" },
        { header: "UoM", dataKey: "uom" },
        { header: "Price Value", dataKey: "price_value" },
        { header: "Gross Value", dataKey: "gross_value" },
      ];

      let grossUp = 0;

      let body = invoiceLines.map((line) => {
        grossUp +=
          line &&
          line.Information &&
          line.Information.gross_up &&
          invoice.Sources.toLowerCase() === "generate"
            ? line.Information.gross_up
            : 0;
        return {
          product: line.ItemName,
          qty: line.Qty,
          uom: line.Unit,
          price_value: `${this.priceFormatNoDecimal(line.Price)} / 1${
            line.Unit ? line.Unit : ""
          }`,
          gross_value: this.priceFormatNoDecimal(
            line &&
              line.Information &&
              line.Information.subtotal_before_discount
              ? line.Information.subtotal_before_discount
              : line.Qty * line.Price
          ),
        };
      });

      // const subtotal = invoice.Subtotal - invoice.Discount;
      const dp = 0;
      const total = [
        {
          product: "",
          qty: "",
          uom: "GROSS TOTAL",
          price_value: "",
          gross_value: this.priceFormatNoDecimal(
            invoice.Sources.toLowerCase() === "generate"
              ? invoice.Subtotal + invoice.Discount
              : invoice.Subtotal
          ),
        },
        {
          product: "",
          qty: "",
          uom: "DISCOUNT",
          price_value: "",
          gross_value: `${this.priceFormatNoDecimal(invoice.Discount)}-`,
        },
        {
          product: "",
          qty: "",
          uom: "SUBTOTAL",
          price_value: "",
          gross_value: this.priceFormatNoDecimal(
            invoice.Sources.toLowerCase() === "generate"
              ? invoice.Subtotal
              : invoice.Subtotal - invoice.Discount
          ),
        },
        {
          product: "",
          qty: "",
          uom: "GROSS UP",
          price_value: "",
          gross_value: this.priceFormatNoDecimal(grossUp),
        },
        {
          product: "",
          qty: "",
          uom: "DP",
          price_value: "",
          gross_value: this.priceFormatNoDecimal(dp),
        },
        {
          product: "",
          qty: "",
          uom: "DPP",
          price_value: "",
          gross_value: this.priceFormatNoDecimal(
            invoice.Sources.toLowerCase() === "generate"
              ? invoice.Subtotal - dp + grossUp
              : invoice.Subtotal - invoice.Discount - dp + grossUp
          ),
        },
        {
          product: "",
          qty: "",
          uom: `PPN ${invoice.TaxRate}%`,
          price_value: "",
          gross_value: this.priceFormatNoDecimal(invoice.TaxAmount),
        },
        {
          product: "",
          qty: "",
          uom: "TOTAL",
          price_value: "",
          gross_value: this.priceFormatNoDecimal(invoice.Total),
        },
      ];

      body = body.concat(total);

      autoTable(pdf, {
        startY: cursorY,
        columns: header,
        body: body,
        styles: {
          lineWidth: 0,
          fontSize: 11,
          font: "courier",
          valign: "middle",
        },
        showHead: "everyPage",
        columnStyles: {
          product: { cellWidth: 10, cellPadding: 0 },
          qty: { cellPadding: 0 },
          uom: { cellPadding: 0 },
          price_value: { cellPadding: 0 },
          gross_value: { cellPadding: 0 },
        },
        margin: {
          top: this.setPos(pageHeight, cursorY, padding),
          bottom: this.setPos(pageHeight, 1.5, padding),
        },
        theme: "plain",
        // margin: { left: this.setPos(pageWidth, pageWidth - 10, 1), right: 1 },
        didParseCell(data) {
          const columnIndex = data.column.index;
          const rowIndex = data.row.index;
          if (columnIndex === 1 || columnIndex === 4) {
            data.cell.styles.halign = "right";
            data.cell.styles.cellPadding = 0.1;
          }

          if (rowIndex === body.length - 8) {
            data.cell.styles.minCellHeight = 0.5;
            data.cell.styles.valign = "bottom";
          }

          if (rowIndex >= body.length - 8) {
            data.cell.styles.fontStyle = "bold";
          }
        },
        didDrawPage: (data) => {
          if (prevPage !== data.pageCount) {
            isDrawLine = true;
            prevPage = data.pageCount;
          }
        },
        didDrawCell: (data) => {
          // pdf.text(
          //   "Hello",
          //   this.setPos(pageWidth, 0.5, padding),
          //   this.setPos(pageHeight, cursorY, padding)
          // );
          const rowIndex = data.row.index;
          const cellX = data.cell.x;
          const cellY = data.cell.y;
          if (rowIndex === 0 || rowIndex === body.length - 8) {
            pdf.setLineWidth(0.01);
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineDashPattern([0.2, 0.2], 0);
            pdf.line(cellX, cellY, cellX + data.cell.width, cellY);
            if (isDrawLine) {
              isDrawLine = false;
              isDraw = true;
            }
          } else if (isDraw) {
            pdf.setLineWidth(0.01);
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineDashPattern([0.2, 0.2], 0);
            pdf.line(cellX, cellY, cellX, cellY + data.cell.height);
            isDraw = false;
          }
        },
      });

      return pdf.lastAutoTable.finalY;
    },
    generateHeader(pdf, resp, cursorY, pageWidth, pageHeight, padding) {
      const invoiceData = resp.data.invoice;
      const customer = resp.data.customer;
      const salesOrder = resp.data.salesOrder[0];
      const salesMan = resp.data.salesman[0];
      const branch = resp.data.branch;
      const realCursor = cursorY;

      pdf.text(
        "PT. SINARMAS DISTRIBUSI NUSANTARA",
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY += 0.45;
      // pdf.text(
      //   "Cab. Jakarta Selatan",
      //   this.setPos(pageWidth, 0.5, padding),
      //   this.setPos(pageHeight, cursorY, padding)
      // );
      // cursorY += 0.45;

      // const address =
      //   "Pergudangan Malatex Jl. Raya Bogor KM.30 Kel. Tugu, Kec. Cimanggis Depok";
      // const addrLine = pdf.splitTextToSize(
      //   address,
      //   this.setPos(pageWidth, pageWidth / 2 - 1, padding)
      // );
      // const addrHeight = pdf.getTextDimensions(address).h;

      // pdf.text(
      //   this.setPos(pageWidth, 0.5, padding),
      //   this.setPos(pageHeight, cursorY, padding),
      //   addrLine
      // );

      const address = `${branch.Address}, ${branch.SubDistrict}, ${branch.District}, ${branch.City}`;
      const addrLine = pdf.splitTextToSize(
        address,
        this.setPos(pageWidth, pageWidth / 2 - 1, padding)
      );
      const addrHeight = pdf.getTextDimensions(address).h;

      pdf.text(
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding),
        addrLine
      );

      cursorY += 1 + addrHeight * addrLine.length;

      pdf.text(
        "021-87727150",
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY += 0.8;

      pdf.text(
        "NPWP SDN: 01.604.506.4-007.000",
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY += 0.8;

      const supplier = customer.name;
      const supplierAddr = `${customer.address.toUpperCase()}, ${customer.sub_district.toUpperCase()}, ${customer.district.toUpperCase()}, ${customer.city.toUpperCase()}`;
      const supplierAddrLine = pdf.splitTextToSize(
        supplierAddr,
        this.setPos(pageWidth, pageWidth / 2 - 1, padding)
      );

      pdf.text(
        "Payer :",
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY += 0.45;

      pdf.text(
        supplier,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY += 0.45;

      pdf.text(
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding),
        supplierAddrLine
      );

      const custAddrHeight = pdf.getTextDimensions(supplierAddr).h;
      cursorY += 0.5 + custAddrHeight * supplierAddrLine.length;

      pdf.text(
        `NPWP Customer : ${customer.npwp === "" ? "-" : customer.npwp}`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY = realCursor;

      const faktur = "FAKTUR";
      const billing = "(BILLING)";

      const billingWidth = pdf.getTextDimensions(billing).w;
      const fakturWidth = pdf.getTextDimensions(faktur).w;
      const xPos =
        pageWidth - pageWidth / 4 - 0.5 + (billingWidth - fakturWidth) / 2;
      pdf.setFontSize(12);
      pdf.setFont("courier", "bold");

      pdf.text(
        faktur,
        this.setPos(pageWidth, xPos, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY += 0.4;

      pdf.text(
        billing,
        this.setPos(pageWidth, pageWidth - pageWidth / 4 - 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );
      cursorY += 0.3;

      this.dashedLine(
        pdf,
        pageWidth / 2 + 1,
        cursorY,
        pageWidth + 0.5,
        cursorY,
        pageWidth + 0.5,
        pageHeight,
        padding
      );

      cursorY += 1.3;

      cursorY = this.generateInvoiceInformation(
        pdf,
        invoiceData,
        salesOrder,
        salesMan,
        customer,
        cursorY,
        pageWidth
      );

      return cursorY;
    },
    dashedLine(pdf, x1, y1, x2, y2, pageWidth, pageHeight, padding) {
      pdf.setLineWidth(0.01);
      pdf.setDrawColor(0, 0, 0);
      pdf.setLineDashPattern([0.2, 0.2], 0);
      pdf.line(
        this.setPos(pageWidth, x1, padding),
        this.setPos(pageHeight, y1, padding),
        this.setPos(pageWidth, x2, padding),
        this.setPos(pageHeight, y2, padding)
      );
    },
    generateOtherInfo(pdf, resp, cursorY, pageWidth, pageHeight, padding) {
      const invoice = resp.data.invoice;
      const payment = resp.data.payment[0];
      let cursor = cursorY;
      const salesOrder = resp.data.salesOrder[0];
      const saying = this.generateNumberSaying(invoice.Total) + " RUPIAH";
      const sayingLines = pdf.splitTextToSize(saying, pageWidth - 2);

      this.dashedLine(
        pdf,
        0.5,
        cursor,
        pageWidth + 0.5,
        cursor,
        pageWidth + 0.5,
        pageHeight,
        padding
      );

      cursor += 0.5;
      pdf.text(
        "SAY :",
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );
      pdf.text(
        this.setPos(pageWidth, 2.0, padding),
        this.setPos(pageHeight, cursor, padding),
        sayingLines
      );

      const sayingHeight = pdf.getTextDimensions(saying).h * sayingLines.length;

      cursor += sayingHeight;

      this.dashedLine(
        pdf,
        0.5,
        cursor,
        pageWidth + 0.5,
        cursor,
        pageWidth + 0.5,
        pageHeight,
        padding
      );

      cursor += 0.5;

      pdf.text(
        `Sales Order : ${
          salesOrder && salesOrder.Code ? salesOrder.Code : "-"
        }`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor += 0.5;

      pdf.text(
        `Customer PO. No. : ${invoice.InvoiceReferenceCode}`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor += 0.5;

      pdf.text(
        `Message : ${invoice.Notes}`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor += 0.5;

      this.dashedLine(
        pdf,
        0.5,
        cursor,
        pageWidth + 0.5,
        cursor,
        pageWidth + 0.5,
        pageHeight,
        padding
      );

      cursor += 1;

      if (cursor > pageHeight - 1.5) {
        pdf.addPage();
        cursor = 1;
      }

      pdf.text(
        `Notes:`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor += 0.5;

      pdf.setFont("courier", "normal");

      pdf.text(
        `This is a computer generated document and no signature is required`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor += 1;

      pdf.setFont("courier", "bold");
      pdf.text(
        `Bank Account : ${
          payment && payment.BankAccountNumber ? payment.BankAccountNumber : ""
        }`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );
    },
    async printPDFSingle(data, isPrint) {
      try {
        this.$store.commit("arInvoice/setInvoice", data);

        const padding = 1;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        // let cursorY = 0;

        pdf.setFontSize(13);
        pdf.setFont("courier", "bold");
        // const invoiceIDs = this.$store.getters["arInvoice/getselectedInvoicesIds"];

        const url = await invoiceHelper.printPDF(
          pdf,
          null,
          isPrint,
          pageWidth,
          pageHeight,
          padding
        );

        if (isPrint) {
          await this.updatePrintBulk();
          sessionStorage.setItem("pdfBlob", url);
          this.$store.commit("arInvoice/resetInvoice");
          this.$refs.printButton.$el.click();
        } else {
          // const url = pdf.output("dataurlstring");
          // this.pdfUrl = url + "#toolbar=0";
          this.pdfUrl = url;
          this.pdfProxy = pdf;
          this.handleShowPopUp();
          this.$vs.loading.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async handlePrintMultiplePDFPreview() {
      try {
        let fileName;
        if (this.$store.state.arInvoice.selectedInvoices.length === 1) {
          fileName = `invoice-${this.$store.state.arInvoice.selectedInvoices[0].Code}.pdf`;
        } else {
          fileName = `invoice-bulk_${moment().format("DD-MM-YYYY")}.pdf`;
        }
        await this.updatePrintBulk();
        this.pdfProxy.save(fileName);
        this.handleClosePopUp();
        this.$store.commit("arInvoice/resetInvoice");
      } catch (e) {
        console.log(e);
      }
    },

    async updatePrintBulk() {
      try {
        this.$vs.loading();
        const resp = await bulkUpdateInvoice(
          this.$store.getters["arInvoice/getselectedInvoicesIds"]
        );
        if (resp.code > 299) {
          throw new Error("Failed to update print count");
        }
        this.$vs.loading.close();
        this.reloadData(this.params);
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        throw e;
      }
    },
    async printPDF(invoice, isPrint = false) {
      try {
        this.$vs.loading();
        this.selectedInvoice = invoice;
        const resp = await this.getInvoiceData(invoice.ID);
        if (resp.code > 299) {
          throw new Error("Failed to get invoice data");
        }

        if (resp.data.invoiceLine.length === 0) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Print",
            text: "Invoice is empty, cannot be printed",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }

        if (resp.data.invoiceLine.length === 0) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Print",
            text: "Invoice is empty, cannot be printed",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }

        const padding = 1;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        let cursorY = 0;

        pdf.setFontSize(16);
        pdf.setFont("courier", "bold");

        cursorY += 17;

        cursorY = this.generateInvoiceLine(
          pdf,
          resp,
          cursorY,
          pageWidth,
          pageHeight,
          padding
        );

        cursorY -= 1;

        this.generateOtherInfo(
          pdf,
          resp,
          cursorY,
          pageWidth,
          pageHeight,
          padding
        );

        let invoiceData = resp.data.invoice;
        // console.log(resp.data, "this.selectedInvoice");

        const pageCount = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.text(
            `Page ${i} of ${pageCount}`,
            this.setPos(pageWidth, pageWidth - 3, padding),
            this.setPos(pageHeight, pageHeight - 0.5, padding)
          );

          pdf.text(
            `Print : ${invoiceData.PrintCount + 1}`,
            this.setPos(pageWidth, pageWidth - (pageWidth - 3), padding),
            this.setPos(pageHeight, pageHeight - 0.5, padding)
          );

          this.generateHeader(pdf, resp, 4, pageWidth, pageHeight, padding);
        }

        if (isPrint) {
          const url = pdf.output("blob");
          const urlObject = URL.createObjectURL(url);
          // this.openPdf(url);
          this.$vs.loading.close();

          this.$vs.loading();
          const res = await this.updatePrintCountV2(invoice.ID);
          if (res.code > 299) {
            this.$vs.loading.close();
            throw new Error("Failed to update print count");
          }
          this.$vs.loading.close();
          const newTab = window.open(urlObject, "_blank");
          if (newTab) {
            newTab.onload = () => {
              newTab.print();
            };
          } else {
            console.error(
              "Failed to open new tab. Please allow pop-ups for this site."
            );
          }
        } else {
          const url = pdf.output("dataurlstring");
          this.pdfUrl = url + "#toolbar=0";
          this.pdfProxy = pdf;
          this.handleShowPopUp();
          this.$vs.loading.close();
        }
        // const url = pdf.output("dataurlstring");
        // this.pdfUrl = url + "#toolbar=0";
        // this.pdfProxy = pdf;
        // this.handleShowPopUp();
        // this.$vs.loading.close();
        // pdf.save(docName);
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
    },
    updatePrintCount(id) {
      this.$vs.loading();
      this.$http
        .patch(`api/v1/invoice/print-count/${id}`)
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Print",
              text: "Print Success",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Print",
              text: "Print Failed",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
          this.reloadData(this.params);
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    updatePrintCountV2(id) {
      return this.$http.patch(`api/v1/invoice/print-count/${id}`);
    },
    dateFormat(value) {
      return moment.utc(String(value)).format("DD/MM/YYYY");
    },
    getTerritoryCode(id) {
      if (id === 0) {
        return "";
      }
      const territories = JSON.parse(
        JSON.stringify(this.$store.state.operatingUnit.filter.territory)
      );

      const territory = territories.find(
        (territory) => territory.TerritoryID === id
      );

      return territory.TerritoryCode ? territory.TerritoryCode : "";
    },
    setPos(pageWidth, pos, offset) {
      if (pos >= pageWidth) {
        return pageWidth;
      } else {
        if (pos <= 0) {
          return offset;
        } else {
          return (pos += offset);
        }
      }
    },
    paymentInvoice(invoice) {
      window.scrollTo(0, 0);
      // this.selectedData = invoice;
      // this.detail = true;
      this.$emit("selectData", invoice, "test");
    },
    clickShow(tr) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      // this.$http.get("api/v1/invoice/read",{
      //     params: {
      //         invoice_id:tr.ID
      //     }
      // })
      // var option = this.option.territory.filter(function(territory)  {
      //       return territory.ID == tr.TerritoryID;
      //     })[0]
      this.$emit("changeTab", 1, tr, false);
    },
    clickEdit(tr) {
      console.log(tr);
      this.closeDetail();
      setTimeout(function () {}, 3000);
      // var option = this.option.territory.filter(function(territory)  {
      //       return territory.ID == tr.TerritoryID;
      //     })[0]
      this.$emit("changeTab", 1, tr, true);
    },
    clickDelete(tr) {
      var promotion_ids = [];
      promotion_ids.push(tr.promotion.ID);
      console.log(promotion_ids);
      console.log(tr);
      this.$http
        .delete("api/v1/promotion/delete", {
          data: {
            promotion_ids: promotion_ids,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        });
      this.$vs.loading.close();
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    sendEmail(invoice) {
      this.$http.get("api/v1/invoice/send/" + invoice).then((resp) => {
        console.log(resp);
      });
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      const customer_codes =
        this.$store.getters["customerFilter/getSelectedCustomersCodes"];
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice/data-table", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            territory_id: this.selected.territory.ID,
            territory_ids,
            status: 3,
            payment_term:
              Object.keys(this.paymentTerm).length > 0
                ? this.paymentTerm.name
                : "All",
            start_posting_date:
              Object.keys(this.postingDate).length > 0 &&
              this.postingDate.startDate
                ? this.formatDate(this.postingDate.startDate)
                : undefined,
            end_posting_date:
              Object.keys(this.postingDate).length > 0 &&
              this.postingDate.endDate
                ? this.formatDate(this.postingDate.endDate)
                : undefined,
            customer_codes: customer_codes.includes("All")
              ? undefined
              : customer_codes,
          },
        })
        .then((resp) => {
          this.shouldReload = true;
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.invoices;
            this.responseData = resp.data;
            this.checkAll = this.isAllInvoiceChecked();
          } else {
            this.$vs.loading.close();
          }
        });
      // this.$http
      // .get("/api/v1/master/price-rule", {
      //   params: {
      //     search: params.search,
      //     length: params.length,
      //     page: params.page,
      //     order: "",
      //     sort: "",
      //   }
      // })
    },

    validateInvoice() {
      const invoice = [...this.$store.state.arInvoice.selectedInvoices];
      if (invoice.length === 0) {
        this.$vs.notify({
          title: "Print",
          text: "Please select invoice to print",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      return true;
    },
    async printPDFMultiple(isPrint = false) {
      try {
        if (!this.validateInvoice()) {
          return;
        }

        const padding = 1;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        // let cursorY = 0;

        pdf.setFontSize(13);
        pdf.setFont("courier", "bold");
        // const invoiceIDs = this.$store.getters["arInvoice/getselectedInvoicesIds"];

        const url = await invoiceHelper.printPDF(
          pdf,
          null,
          isPrint,
          pageWidth,
          pageHeight,
          padding
        );

        if (isPrint) {
          await this.updatePrintBulk();
          sessionStorage.setItem("pdfBlob", url);
          this.$store.commit("arInvoice/resetInvoice");
          this.$refs.printButton.$el.click();
        } else {
          // const url = pdf.output("dataurlstring");
          // this.pdfUrl = url + "#toolbar=0";
          this.pdfUrl = url;
          this.pdfProxy = pdf;
          this.handleShowPopUp();
          this.$vs.loading.close();
        }
      } catch (e) {
        console.log(e);
      }
    },

    addCheck(tr) {
      this.$store.commit("arInvoice/setInvoice", tr);
      const selctedInvoices = [...this.$store.state.arInvoice.selectedInvoices];
      const isCheckedAll = this.responseData.invoices.every((item) =>
        selctedInvoices.some((i) => i.ID === item.ID)
      );

      this.checkAll = isCheckedAll;
    },
    isChecked(tr) {
      const invoice = [...this.$store.state.arInvoice.selectedInvoices];
      return invoice.some((item) => item.ID === tr.ID);
    },
    addAllInvoice() {
      this.checkAll = !this.checkAll;
      const payload = {
        invoices: [...this.responseData.invoices],
        checkAll: this.checkAll,
      };
      this.$store.commit("arInvoice/checkInvoice", payload);
    },
    isAllInvoiceChecked() {
      const invoice = [...this.$store.state.arInvoice.selectedInvoices];
      const result = this.responseData.invoices.every((item) =>
        invoice.some((i) => i.ID === item.ID)
      );
      console.log("is all checked?", result);
      return result;
    },
    async printBillingList() {
      try {
        if (!this.validateInvoice()) {
          return;
        }

        const padding = 1;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        const url = await printInvoiceList(
          pdf,
          2.6,
          pageWidth,
          pageHeight,
          padding
        );

        sessionStorage.setItem("pdfBlob", url);
        this.$refs.printButton.$el.click();
        this.$store.commit("arInvoice/resetInvoice");
      } catch (e) {
        console.log(e);
      }
    },
    printOpenTab(tr, isBillingList = false) {
      if (tr) {
        sessionStorage.setItem("invoice_id", JSON.stringify([tr.ID]));
        sessionStorage.setItem("param_type", "id");
      } else {
        if (!this.validateInvoice()) {
          return;
        }
        const invoiceIds =
          this.$store.getters["arInvoice/getselectedInvoicesIds"];
        sessionStorage.setItem("invoice_id", JSON.stringify(invoiceIds));
        sessionStorage.setItem("param_type", "id");
      }

      if (isBillingList) {
        sessionStorage.setItem("is_billing_list", "true");
      } else {
        sessionStorage.setItem("is_billing_list", "false");
      }

      window.open("/billing/print-invoice", "_blank");
      this.$store.commit("arInvoice/resetInvoice");
      this.checkAll = false;
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
