<template>
  <vs-table
    stripe
    bordered
    :data="approvalHistory"
    style="width: 100%;"
  >
    <template slot="thead">
      <vs-th>Role Name</vs-th>
      <vs-th>Level</vs-th>
      <vs-th>Status</vs-th>
    </template>
    <template slot-scope="{ data }">
      <vs-tr :key="`history-${index}`" v-for="(dt, index) in data">
        <vs-td>{{ dt.role_name }}</vs-td>
        <vs-td>{{ dt.role_level }}</vs-td>
        <vs-td class="font-bold"
          ><vs-chip transparent :color="generateChipColor(dt.approval_status)">
            {{ dt.approval_status }}
          </vs-chip></vs-td
        >
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
export default {
  props: {
    approvalHistory: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    generateChipColor(status) {
      if (status === "Approved") {
        return "success";
      } else if (status === "Rejected") {
        return "danger";
      } else {
        return "warning";
      }
    },
  },
};
</script>
